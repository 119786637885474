import React, {useState, useEffect, useRef} from "../../_snowpack/pkg/react.js";
import Loader from "../components/Loader.js";
import {GradeCalendar, WordCalendar} from "../components/Calendar.js";
import {GroupIcon, BookIcon, BorderColorIcon} from "../components/Icons.js";
import {updateToken} from "../auth.js";
import {toast} from "../toast.js";
import "./Admin.css";
function MangeUsers() {
  const [data, setData] = useState();
  const resetLastTestDate = (targetUserName) => {
    fetch("https://words-api.marshall-ku.com/test/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token") || ""
      },
      body: JSON.stringify({
        name: targetUserName
      })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (!response.error) {
        if (response.freshToken) {
          updateToken(response.freshToken);
        }
        if (response.success) {
          toast(response.message);
        } else {
          toast("Something went wrong 😥");
        }
      } else {
        toast(response.message || "Something went wrong 😥");
      }
    });
  };
  useEffect(() => {
    fetch("https://words-api.marshall-ku.com/users", {
      method: "GET"
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Failed to fetch");
      }
    }).then((response) => {
      if (Array.isArray(response) && response.length) {
        setData(response);
      }
    }).catch((error) => {
      console.dir(error);
    });
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h2", null, "Users"), data ? data.map((user, i) => {
    return /* @__PURE__ */ React.createElement("details", {
      key: i,
      className: "admin__user"
    }, /* @__PURE__ */ React.createElement("summary", null, user.name), /* @__PURE__ */ React.createElement("div", {
      className: "admin__user__container"
    }, /* @__PURE__ */ React.createElement("h2", null, "Last Tested"), /* @__PURE__ */ React.createElement("div", {
      className: "admin__user__last-test"
    }, /* @__PURE__ */ React.createElement("div", null, user.lastTestDate), /* @__PURE__ */ React.createElement("button", {
      className: "small-button",
      onClick: () => resetLastTestDate(user.name)
    }, "Reset")), /* @__PURE__ */ React.createElement("h2", null, "Grades"), /* @__PURE__ */ React.createElement(GradeCalendar, {
      user
    })));
  }) : /* @__PURE__ */ React.createElement(Loader, null));
}
function ManageWords() {
  const [data, setData] = useState();
  const [fileName, setFileName] = useState();
  const [includeExample, setIncludeExample] = useState(false);
  const textarea = useRef(null);
  const parseWords = () => {
    if (!textarea.current)
      return;
    textarea.current.value = "";
    autoGrow();
    if (!data || !data.length)
      return;
    let string = "";
    const length = data.length;
    data.forEach((word, i) => {
      let meaning = "";
      if (word.meaning.length === 1) {
        meaning = word.meaning[0];
      } else {
        const meaningLength = word.meaning.length;
        word.meaning.forEach((string2, i2) => {
          meaning += `${string2}${i2 === meaningLength - 1 ? "" : "."}`;
        });
      }
      string += `${word.word}${word.isIdiom ? "!" : ""}
${meaning}${word.example ? `
${word.example}` : ""}${i === length - 1 ? "" : "\n"}`;
    });
    textarea.current.value = string;
    autoGrow();
  };
  const submitWords = () => {
    if (!textarea.current)
      return;
    const split = textarea.current.value.split("\n");
    const tmpWords = [];
    let tmpWord = includeExample ? {
      word: "",
      meaning: [""],
      example: ""
    } : {
      word: "",
      meaning: [""]
    };
    if (includeExample) {
      split.forEach((string, i) => {
        if ((i + 1) % 3 === 0) {
          tmpWord.example = string;
          tmpWords.push(tmpWord);
          tmpWord = {
            word: "",
            meaning: [""],
            example: ""
          };
        } else if ((i + 1) % 3 === 1) {
          if (string.endsWith("!")) {
            tmpWord.isIdiom = true;
          }
          tmpWord.word = string.replace("!", "");
        } else {
          tmpWord.meaning = string.split(".").map((string2) => string2.trim());
        }
      });
    } else {
      split.forEach((string, i) => {
        if ((i + 1) % 2 === 0) {
          tmpWord.meaning = string.split(".").map((string2) => string2.trim());
          tmpWords.push(tmpWord);
          tmpWord = {
            word: "",
            meaning: [""]
          };
        } else {
          if (string.endsWith("!")) {
            tmpWord.isIdiom = true;
          }
          tmpWord.word = string.replace("!", "");
        }
      });
    }
    setData(tmpWords);
    fetch("https://words-api.marshall-ku.com/words/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token") || ""
      },
      body: JSON.stringify({
        name: fileName,
        words: tmpWords
      })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (response.freshToken) {
        updateToken(response.freshToken);
      }
      if (!response.error) {
        if (response.success) {
          toast(response.message);
        } else {
          toast("Something went wrong 😥");
        }
      } else {
        toast(response.message || "Something went wrong 😥");
      }
    });
  };
  const autoGrow = () => {
    const {current} = textarea;
    if (!current)
      return;
    const {scrollY} = window;
    current.style.height = "auto";
    current.style.height = `calc(${current.scrollHeight}px + 1.2rem)`;
    window.scrollTo(0, scrollY);
  };
  useEffect(parseWords, [data]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h2", null, "Words"), /* @__PURE__ */ React.createElement(WordCalendar, {
    setAdminData: setData,
    setAdminFileName: setFileName
  }), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "include-example",
    onChange: (event) => {
      setIncludeExample(event.target.checked);
    }
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "include-example"
  }, "예문 포함")), /* @__PURE__ */ React.createElement("textarea", {
    placeholder: "영단어, 뜻 순으로 입력\r\n뜻 여러개인 단어는 .으로 뜻 구분\r\n숙어면 영단어 마지막에 ! 추가",
    cols: 30,
    rows: 5,
    ref: textarea,
    onKeyUp: autoGrow,
    onChange: autoGrow
  }), /* @__PURE__ */ React.createElement("button", {
    className: "large-button",
    onClick: submitWords
  }, "Submit"));
}
function ManageTest() {
  const [showCorrect, setShowCorrect] = useState(true);
  const [limit, setLimit] = useState(10);
  const handleShowCorrectChange = (event) => {
    const target = event.target;
    setShowCorrect(target.checked);
    console.log(target.checked);
  };
  const handleLimitChange = (event) => {
    const target = event.target;
    setLimit(+target.value);
    console.log(target.value, limit);
  };
  const postConfig = () => {
    fetch("https://words-api.marshall-ku.com/test/config/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token") || ""
      },
      body: JSON.stringify({
        limit,
        showCorrect
      })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (!response.error) {
        if (response.freshToken) {
          updateToken(response.freshToken);
        }
        if (response.success) {
          toast(response.message);
        } else {
          toast("Something went wrong 😥");
        }
      } else {
        toast(response.message || "Something went wrong 😥");
      }
    });
  };
  useEffect(() => {
    fetch("https://words-api.marshall-ku.com/test/config", {
      headers: {
        "x-auth-token": localStorage.getItem("token") || ""
      }
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (!response.error) {
        if (response.freshToken) {
          updateToken(response.freshToken);
        }
        if (response.success) {
          setShowCorrect(response.showCorrect);
          setLimit(response.limit);
        } else {
          toast("Something went wrong 😥");
        }
      } else {
        toast(response.message || "Something went wrong 😥");
      }
    });
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h2", null, "Test"), /* @__PURE__ */ React.createElement("div", {
    className: "admin__options"
  }, /* @__PURE__ */ React.createElement("h3", null, "Display the correct answer onclick"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    checked: showCorrect,
    onChange: handleShowCorrectChange,
    id: "showCorrect"
  }), /* @__PURE__ */ React.createElement("label", {
    htmlFor: "showCorrect"
  }, showCorrect ? "👀 True" : "😑 False")), /* @__PURE__ */ React.createElement("h3", null, "The number of words for test"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("input", {
    type: "number",
    value: limit,
    onChange: handleLimitChange
  })), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
    className: "large-button",
    onClick: postConfig
  }, "Submit"))));
}
export default function Admin() {
  const storedToken = localStorage.getItem("token");
  const [isAdmin, setIsAdmin] = useState(false);
  const [category, setCategory] = useState("users");
  useEffect(() => {
    if (storedToken) {
      fetch("https://words-api.marshall-ku.com/auth", {
        method: "GET",
        headers: {
          "x-auth-token": storedToken
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch");
        }
      }).then((response) => {
        if (response.success) {
          if (response.isAdmin) {
            setIsAdmin(true);
          }
        }
      }).catch((error) => {
        console.dir(error);
      });
    }
  }, []);
  if (!isAdmin)
    return null;
  return /* @__PURE__ */ React.createElement("div", {
    className: `admin admin--${category}`
  }, category === "users" ? /* @__PURE__ */ React.createElement(MangeUsers, null) : category === "words" ? /* @__PURE__ */ React.createElement(ManageWords, null) : /* @__PURE__ */ React.createElement(ManageTest, null), /* @__PURE__ */ React.createElement("nav", {
    className: "admin__nav"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: () => setCategory("users")
  }, /* @__PURE__ */ React.createElement(GroupIcon, null)), /* @__PURE__ */ React.createElement("button", {
    onClick: () => setCategory("words")
  }, /* @__PURE__ */ React.createElement(BookIcon, null)), /* @__PURE__ */ React.createElement("button", {
    onClick: () => setCategory("test")
  }, /* @__PURE__ */ React.createElement(BorderColorIcon, null))));
}
