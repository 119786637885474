import React, {useState, useRef} from "../../_snowpack/pkg/react.js";
import {Redirect} from "../../_snowpack/pkg/react-router-dom.js";
import {updateToken} from "../auth.js";
import {toast} from "../toast.js";
import "./Sign.css";
export default function SignIn() {
  const [authenticated, setAuthenticated] = useState(false);
  const name = useRef(null);
  const password = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("https://words-api.marshall-ku.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name.current?.value,
        password: password.current?.value
      })
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (response.success) {
        if (response.token) {
          updateToken(response.token);
        }
        window.user.name = response.name;
        if (response.isAdmin) {
          window.user.isAdmin = true;
        }
        setAuthenticated(true);
      } else {
        toast("Something went wrong 😥");
      }
    });
  };
  if (!authenticated) {
    return /* @__PURE__ */ React.createElement("form", {
      action: "https://words-api.marshall-ku.com/auth/login",
      method: "post",
      className: "sign sign--in",
      onSubmit: handleSubmit
    }, /* @__PURE__ */ React.createElement("h1", null, "Welcome Back!"), /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("input", {
      type: "text",
      name: "name",
      placeholder: "Name",
      required: true,
      ref: name
    }), /* @__PURE__ */ React.createElement("span", null, "Name")), /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("input", {
      type: "password",
      name: "password",
      placeholder: "Password",
      required: true,
      ref: password
    }), /* @__PURE__ */ React.createElement("span", null, "Password")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
      type: "submit",
      className: "sign__submit"
    }, "Log in")));
  }
  return /* @__PURE__ */ React.createElement(Redirect, {
    to: "/"
  });
}
