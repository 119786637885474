import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import {Link, Redirect} from "../../_snowpack/pkg/react-router-dom.js";
import Loader from "../components/Loader.js";
import {shuffleArray} from "../utils.js";
import {HomeIcon} from "../components/Icons.js";
import {updateToken} from "../auth.js";
import {toast} from "../toast.js";
import "./Test.css";
function WordTest(props) {
  const {
    words,
    problems,
    answers,
    limit,
    showCorrect,
    hasIdioms,
    randomIdiom,
    idioms
  } = props;
  const {length: dataLength} = words;
  const generateRandomNumbers = (mustHave) => {
    const numbers = [mustHave];
    const randomNumber = () => Math.floor(Math.random() * dataLength);
    const size = 4;
    while (numbers.length < size) {
      const number = randomNumber();
      if (!numbers.includes(number) && !answers.has(number) && words[number]) {
        numbers.push(number);
      }
    }
    shuffleArray(numbers);
    return numbers;
  };
  const [index, setIndex] = useState(0);
  const [randomNumbers, setRandomNumbers] = useState(generateRandomNumbers(problems[index]));
  const [done, setDone] = useState(false);
  const [incorrect, setIncorrect] = useState(0);
  const [animating, setAnimating] = useState(true);
  const timeLimit = 5e3;
  const animationTime = showCorrect ? 1e3 : 500;
  const increaseIndex = (incorrect2) => {
    setAnimating(false);
    if (index !== limit - 1) {
      setTimeout(() => {
        document.querySelectorAll(".clicked").forEach((element) => {
          element.classList.remove("clicked");
        });
        answers.add(problems[index + 1]);
        setRandomNumbers(generateRandomNumbers(problems[index + 1]));
        setIndex(index + 1);
        setAnimating(true);
      }, animationTime);
    } else {
      setTimeout(() => {
        setDone(true);
        fetch("https://words-api.marshall-ku.com/test/result", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token") || ""
          },
          body: JSON.stringify({
            grade: ((limit - incorrect2) / limit * 100).toFixed(2)
          })
        }).then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Failed to fetch");
        }).then((response) => {
          if (!response.error) {
            if (response.freshToken) {
              updateToken(response.freshToken);
            }
            if (response.success) {
              toast("Successfully submitted 🎉");
            } else {
              toast("Something went wrong 😥");
            }
          } else {
            toast(response.message || "Something went wrong 😥");
          }
        });
      }, 1e3);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!animating)
      return;
    const target = event.target;
    const submittedAnswer = target.innerText;
    const correctAnswer = hasIdioms && index === limit - 1 ? idioms[randomIdiom].word : words[problems[index]].word;
    target.classList.add("clicked");
    if (submittedAnswer.toLocaleLowerCase() !== correctAnswer.toLocaleLowerCase()) {
      increaseIndex(incorrect + 1);
      setIncorrect(incorrect + 1);
    } else {
      increaseIndex(incorrect);
    }
  };
  const Buttons = () => {
    return /* @__PURE__ */ React.createElement("ul", {
      className: "question__words"
    }, randomNumbers.map((number, i) => {
      return /* @__PURE__ */ React.createElement("button", {
        key: number,
        onClick: handleSubmit,
        className: `large-button ${!animating && showCorrect && (hasIdioms ? index === limit - 1 ? i === randomIdiom : number === problems[index] : number === problems[index]) ? "answer" : ""}`
      }, hasIdioms && index === limit - 1 ? idioms[i].word : words[number].word);
    }));
  };
  useEffect(() => {
    answers.add(problems[index]);
  }, []);
  useEffect(() => {
    const timer = done ? setTimeout(() => {
    }, 0) : setTimeout(() => {
      increaseIndex(incorrect + 1);
      setIncorrect(incorrect + 1);
    }, timeLimit);
    return () => {
      clearTimeout(timer);
    };
  }, [animating, index, done]);
  if (done) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "center-container done"
    }, /* @__PURE__ */ React.createElement("h2", {
      className: "done__title"
    }, "Congratulations! 🎉"), /* @__PURE__ */ React.createElement("div", {
      className: "done__info"
    }, /* @__PURE__ */ React.createElement("h3", null, "오답수 : ", incorrect)), /* @__PURE__ */ React.createElement("div", {
      className: "done__buttons"
    }, /* @__PURE__ */ React.createElement(Link, {
      className: "done__button",
      to: "/"
    }, /* @__PURE__ */ React.createElement(HomeIcon, null))));
  }
  if (props.type === "sentence") {
    const currentWord = hasIdioms && index === limit - 1 ? idioms[randomIdiom] : words[problems[index]];
    return /* @__PURE__ */ React.createElement("div", {
      className: `question ${showCorrect ? "question--show-correct" : ""}`
    }, /* @__PURE__ */ React.createElement("div", {
      className: `question__time-limit ${animating ? "animating" : ""}`
    }), /* @__PURE__ */ React.createElement("div", {
      className: "question__sentence"
    }, /* @__PURE__ */ React.createElement("div", null, currentWord.example?.split(" ").map((word, i) => {
      if (word === currentWord.word)
        return /* @__PURE__ */ React.createElement("span", {
          className: "blank",
          key: i
        });
      return /* @__PURE__ */ React.createElement("span", {
        key: i
      }, word);
    }))), /* @__PURE__ */ React.createElement(Buttons, null));
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: `question ${showCorrect ? "question--show-correct" : ""}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: `question__time-limit ${animating ? "animating" : ""}`
  }), /* @__PURE__ */ React.createElement("ul", {
    className: "question__meaning"
  }, (hasIdioms && index === limit - 1 ? idioms[randomIdiom] : words[problems[index]]).meaning.map((meaning, i) => {
    return /* @__PURE__ */ React.createElement("li", {
      key: i
    }, meaning);
  })), /* @__PURE__ */ React.createElement(Buttons, null));
}
export default function Test() {
  const generateRandomNumbers = (max, limit2) => {
    const numbers = [];
    const randomNumber = () => Math.floor(Math.random() * max);
    while (numbers.length < limit2) {
      const number = randomNumber();
      if (!numbers.includes(number)) {
        numbers.push(number);
      }
    }
    return numbers;
  };
  const [data, setData] = useState();
  const [limit, setLimit] = useState(0);
  const [testType, setTestType] = useState();
  const [showCorrect, setShowCorrect] = useState();
  const [signInRequired, setSignInRequired] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const answers = new Set([]);
  const fetchWords = () => {
    fetch("https://words-api.marshall-ku.com/test", {
      headers: {
        "x-auth-token": localStorage.getItem("token") || ""
      }
    }).then((response) => {
      try {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Couldn't fetch data");
        }
      } catch (error) {
        throw new Error("Couldn't parse data");
      }
    }).then((response) => {
      if (response.hasOwnProperty("words")) {
        response = response;
        const {words, limit: limit2, type, showCorrect: showCorrect2, freshToken} = response;
        setData(words);
        setLimit(limit2);
        setTestType(type);
        setShowCorrect(showCorrect2);
        if (freshToken) {
          updateToken(freshToken);
        }
      } else {
        response = response;
        if (response.signInRequired) {
          setSignInRequired(true);
        } else if (response.error) {
          toast(response.message);
          setRedirect(true);
        }
      }
    }).catch((error) => {
      console.dir(error);
    });
  };
  useEffect(() => {
    fetchWords();
  }, []);
  if (data && testType && limit && showCorrect !== void 0) {
    shuffleArray(data);
    const wordsWithoutIdioms = data.filter((word) => !word.isIdiom);
    const tmpWords = wordsWithoutIdioms.filter((_, i) => i < limit);
    const tmpIdioms = data.filter((word) => word.isIdiom);
    const hasIdioms = tmpIdioms.length !== 0;
    const randomIdiom = Math.min(Math.floor(Math.random() * tmpIdioms.length), 3);
    const idioms = !hasIdioms ? [] : tmpIdioms.length < 4 ? tmpIdioms.concat(tmpWords.filter((_, i) => i < 4 - tmpIdioms.length)) : tmpIdioms;
    const problems = generateRandomNumbers(wordsWithoutIdioms.length, limit);
    return /* @__PURE__ */ React.createElement(WordTest, {
      type: testType,
      words: wordsWithoutIdioms,
      idioms,
      problems,
      limit,
      answers,
      hasIdioms,
      randomIdiom,
      showCorrect
    });
  }
  if (signInRequired) {
    return /* @__PURE__ */ React.createElement(Redirect, {
      to: "/login"
    });
  } else if (redirect) {
    return /* @__PURE__ */ React.createElement(Redirect, {
      to: "/"
    });
  } else {
    return /* @__PURE__ */ React.createElement("div", {
      className: "center-container"
    }, /* @__PURE__ */ React.createElement(Loader, null));
  }
}
