import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import {Link, useHistory} from "../../_snowpack/pkg/react-router-dom.js";
import {GradeCalendar} from "../components/Calendar.js";
import Loader from "../components/Loader.js";
import {resetToken} from "../auth.js";
import "./FrontPage.css";
export default function FrontPage() {
  const history = useHistory();
  const [user, setUser] = useState();
  const {name, isAdmin} = window.user;
  if (!name) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "front"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "front__title"
    }, "With English"), /* @__PURE__ */ React.createElement("div", {
      className: "front__buttons"
    }, /* @__PURE__ */ React.createElement(Link, {
      className: "large-button large-button--fancy",
      to: "/login"
    }, "Log In"), /* @__PURE__ */ React.createElement(Link, {
      className: "large-button large-button--fancy",
      to: "/signup"
    }, "Sign Up")));
  }
  useEffect(() => {
    if (name) {
      fetch(`https://words-api.marshall-ku.com/users/user/${name}`).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch");
        }
      }).then((response) => {
        if (response.name && response.grades) {
          setUser(response);
        }
      }).catch((error) => {
        console.dir(error);
      });
    }
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "front front--user"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "front__banner"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "front__banner__content"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "profile"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/logo/logo-96x96.png",
    alt: name
  })), /* @__PURE__ */ React.createElement("h1", {
    className: isAdmin ? "admin" : ""
  }, name)), /* @__PURE__ */ React.createElement("svg", {
    className: "curve curve--top",
    width: "100%",
    height: "50",
    viewBox: "0 0 200 45.68",
    preserveAspectRatio: "none"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M0,8C33.65-10.84,50.37,29.77,68.88,29.77S117.26,0,149.26,0,200,25.55,200,25.55V45.68H0Z"
  }))), user ? /* @__PURE__ */ React.createElement("div", {
    className: "front__board"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "front__board__nav"
  }, isAdmin && /* @__PURE__ */ React.createElement(Link, {
    className: "large-button large-button--fancy",
    to: "/admin"
  }, "🚧 Admin"), /* @__PURE__ */ React.createElement(Link, {
    className: "large-button large-button--fancy",
    to: "/words"
  }, "📖 Words"), /* @__PURE__ */ React.createElement(Link, {
    className: "large-button large-button--fancy",
    to: "/memorize"
  }, "🤯 Memorize"), /* @__PURE__ */ React.createElement(Link, {
    className: "large-button large-button--fancy",
    to: "/test"
  }, "🤔 Test")), /* @__PURE__ */ React.createElement("h2", null, "🗓️ Last tested"), /* @__PURE__ */ React.createElement("div", null, user.lastTestDate || "Never"), /* @__PURE__ */ React.createElement("h2", null, "💡 Grades"), /* @__PURE__ */ React.createElement(GradeCalendar, {
    user
  }), /* @__PURE__ */ React.createElement("div", {
    className: "front__small-text"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: () => {
      resetToken();
      history.push("/login");
    },
    className: "small-button"
  }, "Sign out"))) : /* @__PURE__ */ React.createElement("div", {
    className: "front__loading"
  }, /* @__PURE__ */ React.createElement(Loader, null)));
}
