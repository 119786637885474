import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import {shuffleArray} from "../utils.js";
import Loader from "../components/Loader.js";
import {HomeIcon, RenewIcon, DoneIcon} from "../components/Icons.js";
import "./Memorize.css";
function MemorizeWords(props) {
  const [index, setIndex] = useState(0);
  const [done, setDone] = useState(false);
  const [reveal, setReveal] = useState(false);
  const {data} = props;
  const {length} = data;
  const wordSwapInterval = 3e3;
  const wordRevealInterval = 2e3;
  const increaseIndex = () => {
    if (index === length - 1) {
      setDone(true);
    } else {
      setIndex(index + 1);
      setReveal(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(increaseIndex, wordSwapInterval);
    const reveal2 = setTimeout(() => setReveal(true), wordRevealInterval);
    document.documentElement.classList.remove("aware");
    return () => {
      clearTimeout(timer);
      clearTimeout(reveal2);
    };
  }, [index, setIndex]);
  if (done) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "center-container done"
    }, /* @__PURE__ */ React.createElement("h2", {
      className: "done__title"
    }, "Done 🎉"), /* @__PURE__ */ React.createElement("div", {
      className: "done__buttons"
    }, /* @__PURE__ */ React.createElement(Link, {
      className: "done__button",
      to: "/"
    }, /* @__PURE__ */ React.createElement(HomeIcon, null)), /* @__PURE__ */ React.createElement("button", {
      className: "done__button",
      onClick: () => {
        shuffleArray(data);
        setIndex(0);
        setReveal(false);
        setDone(false);
      }
    }, /* @__PURE__ */ React.createElement(RenewIcon, null))));
  } else {
    const currentWord = data[index];
    return /* @__PURE__ */ React.createElement("div", {
      className: "memorize"
    }, /* @__PURE__ */ React.createElement("h2", {
      className: "memorize__word"
    }, /* @__PURE__ */ React.createElement("div", null, currentWord.word)), /* @__PURE__ */ React.createElement("ul", {
      className: `memorize__meaning memorize__hidden${reveal ? " reveal" : ""}`
    }, currentWord.meaning.map((meaning, index2) => {
      return /* @__PURE__ */ React.createElement("li", {
        key: index2
      }, meaning);
    })), /* @__PURE__ */ React.createElement("button", {
      className: "memorize__aware",
      onClick: increaseIndex
    }, /* @__PURE__ */ React.createElement(DoneIcon, null), " I know this"));
  }
}
export default function Memorize() {
  const [data, setData] = useState();
  const fetchWords = () => {
    fetch("https://words-api.marshall-ku.com/words/today").then((response) => {
      try {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Couldn't fetch data");
        }
      } catch (error) {
        throw new Error("Couldn't parse data");
      }
    }).then((response) => {
      setData(response);
    }).catch((error) => {
      console.log(error);
    });
  };
  useEffect(() => {
    fetchWords();
  }, []);
  if (data) {
    return /* @__PURE__ */ React.createElement(MemorizeWords, {
      data
    });
  } else {
    return /* @__PURE__ */ React.createElement("div", {
      className: "center-container"
    }, /* @__PURE__ */ React.createElement(Loader, null));
  }
}
