import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import speak from "../speaker.js";
import Loader from "../components/Loader.js";
import {VolumeUpIcon, HomeIcon} from "../components/Icons.js";
import "./Words.css";
function WordsList(props) {
  const {data} = props;
  const now = new Date();
  if (now.getHours() > 22) {
    now.setDate(new Date().getDate() + 1);
  }
  const handleClick = (e) => {
    const target = e.target;
    speak(target.dataset.message?.replace(/\(.*\)/, "") || "");
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "words"
  }, /* @__PURE__ */ React.createElement("h1", null, `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`), /* @__PURE__ */ React.createElement("div", {
    className: "table"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "table__title"
  }, "단어"), /* @__PURE__ */ React.createElement("div", {
    className: "table__title"
  }, "뜻"), data.map((word) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
      className: word.aware ? "aware" : ""
    }, word.word, /* @__PURE__ */ React.createElement("button", {
      "data-message": word.word,
      onClick: handleClick
    }, /* @__PURE__ */ React.createElement(VolumeUpIcon, null))), /* @__PURE__ */ React.createElement("ul", null, word.meaning.map((meaning) => {
      return /* @__PURE__ */ React.createElement("li", null, meaning);
    })));
  })), /* @__PURE__ */ React.createElement(Link, {
    to: "/",
    className: "words__button"
  }, /* @__PURE__ */ React.createElement(HomeIcon, null)));
}
export default function Words() {
  const [data, setData] = useState();
  const fetchWords = () => {
    fetch("https://words-api.marshall-ku.com/words/today").then((response) => {
      try {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Couldn't fetch data");
        }
      } catch (error) {
        throw new Error("Couldn't parse data");
      }
    }).then((response) => {
      setData(response);
    }).catch((error) => {
      console.log(error);
    });
  };
  useEffect(() => {
    fetchWords();
  }, []);
  if (data) {
    return /* @__PURE__ */ React.createElement(WordsList, {
      data
    });
  } else {
    return /* @__PURE__ */ React.createElement("div", {
      className: "center-container"
    }, /* @__PURE__ */ React.createElement(Loader, null));
  }
}
