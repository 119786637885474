import React, {useState, useEffect} from "../../_snowpack/pkg/react.js";
import Calendar from "../../_snowpack/pkg/react-calendar.js";
import {Stamp} from "./Icons.js";
import "./Calendar.css";
export function GradeCalendar(props) {
  const {user} = props;
  const grades = user.grades.map((user2) => {
    return {
      date: new Date(user2.date),
      grade: user2.grade
    };
  });
  const [value, onChange] = useState(new Date());
  const checkIfSameDay = (day1, day2) => {
    return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
  };
  const Tile = (props2) => {
    const currentDate = new Date(props2.date);
    const filter = grades.filter((day) => checkIfSameDay(day.date, currentDate));
    if (!filter.length)
      return null;
    const {grade} = filter[0];
    return /* @__PURE__ */ React.createElement("div", {
      className: grade === 100 ? "perfect" : grade < 80 ? "fail" : ""
    }, grade, grade === 100 && /* @__PURE__ */ React.createElement(Stamp, null));
  };
  return /* @__PURE__ */ React.createElement(Calendar, {
    onChange,
    value,
    tileContent: Tile
  });
}
export function WordCalendar(props) {
  const {setAdminData, setAdminFileName} = props;
  const [value, onChange] = useState(new Date());
  const [date, setDate] = useState(value);
  const [data, setData] = useState([]);
  const checkIfSameDay = (day1, day2) => {
    return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();
  };
  const Tile = (props2) => {
    const currentDate = new Date(props2.date);
    const filter = data.filter((day) => checkIfSameDay(day, currentDate));
    if (!filter.length)
      return null;
    return /* @__PURE__ */ React.createElement("div", null, "*");
  };
  const fetchDir = () => {
    fetch(`https://words-api.marshall-ku.com/words/${date.getFullYear()}/${date.getMonth() + 1}`).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (!response.error && response.words) {
        setData(response.words.map((fileName) => new Date(`${date.getFullYear()}/${date.getMonth() + 1}/${fileName.replace(".json", "")}`)));
      }
    }).catch((error) => {
      console.dir(error);
    });
  };
  const fetchFile = () => {
    const fileName = `${value.getFullYear()}/${value.getMonth() + 1}/${value.getDate()}.json`;
    fetch(`https://words-api.marshall-ku.com/words/${fileName}`).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Failed to fetch");
    }).then((response) => {
      if (Array.isArray(response)) {
        setAdminData(response);
        setAdminFileName(fileName);
      } else {
        setAdminData(void 0);
        setAdminFileName(fileName);
      }
    }).catch((error) => {
      console.dir(error);
    });
  };
  useEffect(fetchDir, [date]);
  useEffect(fetchFile, [value]);
  return /* @__PURE__ */ React.createElement(Calendar, {
    onChange,
    onActiveStartDateChange: ({activeStartDate}) => setDate(new Date(activeStartDate)),
    value,
    tileContent: Tile
  });
}
