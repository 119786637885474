import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
import {BrowserRouter, Route} from "../_snowpack/pkg/react-router-dom.js";
import FrontPage from "./router/FrontPage.js";
import Words from "./router/Words.js";
import Memorize from "./router/Memorize.js";
import Test from "./router/Test.js";
import Admin from "./router/Admin.js";
import SignIn from "./router/SignIn.js";
import SignUp from "./router/SignUp.js";
import Loader from "./components/Loader.js";
export default function App() {
  const storedToken = localStorage.getItem("token");
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window.user = {};
    if (storedToken) {
      fetch("https://words-api.marshall-ku.com/auth", {
        method: "GET",
        headers: {
          "x-auth-token": storedToken
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch");
        }
      }).then((response) => {
        if (response.success) {
          window.user.name = response.name;
          if (response.isAdmin) {
            window.user.isAdmin = true;
          }
        }
      }).catch((error) => {
        console.dir(error);
      }).finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
  }, []);
  if (!loaded) {
    return /* @__PURE__ */ React.createElement(BrowserRouter, {
      basename: "/"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "center-container"
    }, /* @__PURE__ */ React.createElement(Loader, null)));
  }
  return /* @__PURE__ */ React.createElement(BrowserRouter, {
    basename: "/"
  }, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/",
    component: FrontPage
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/words",
    component: Words
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/memorize",
    component: Memorize
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/test",
    component: Test
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/admin",
    component: Admin
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/login",
    component: SignIn
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/signup",
    component: SignUp
  }));
}
